.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.stickytoolbar-default {
  top: 0px;
  position: relative;
  z-index: none;
  width: 100%;
}

.stickytoolbar {
  top: 64px;
  position: fixed;
  z-index: 998;

  @media (min-width: 993px){
    width: calc(100% - 19rem);
  }

  @media (max-width: 992px){
    width: calc(100% - 3rem);
  }
}

.text-right {
  text-align: right;
}

.input-group.input-group-sm .header .dropdown-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.input-group-sm .header .dropdown-container,
.input-group.input-group-sm .header .dropdown-container .dropdown-heading {
  height: calc(1.5em + 0.5rem + 2px);
  min-height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.71094rem;
  width: 10vw;

  @media (max-width: 992px) {
    width: 60vw;
  }
}

.header .dropdown-container .dropdown-heading {
  padding-right: 0px
}

div .dropdown-container .dropdown-heading .arrow {
  display: block;
  padding: 0.47rem 0.75rem 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-gray-700);
  background-color: var(--bs-custom-white);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 12px 12px;
}

div .dropdown-container .dropdown-heading .clear {
  display: block;
  padding: 0.47rem 0.75rem 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-gray-700);
  background-color: var(--bs-custom-white);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 8px 8px;
}

.dropdown-container .dropdown-content {
  width: max-content !important;
  z-index: 2 !important;
}

.dropdown-container,
.dropdown-container .dropdown-heading {
  height: 36.6px;
}

.dropdown-container .dropdown-content .options .select-item {
  margin-bottom: 0rem;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  font-weight: 400;
}

.btn-group,
.btn-group-vertical {
  // Bring the hover, focused, and "active" buttons to the front to overlay
  // the borders properly
  > .btn-check:checked + .btn,
  > .btn-check:focus + .btn,
  > .btn:hover,
  > .btn:focus,
  > .btn:active,
  > .btn.active {
    z-index: auto;
  }
}

.table.dataTable > thead > tr > th {
  font-size: 11px;
}

