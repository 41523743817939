@import "~react-select-input/es/react-select-input.css";
@import "~react-datepicker/dist/react-datepicker.css";

.loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9;
}

.vertical-menu ~ .main-content > .loader-container {
  padding-left: 250px;
}

.hover__cursor {
  cursor: pointer !important;
}

.card.eq-height {
  min-height: calc(100% - 24px);
}

form.app-period-selector {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  form.app-period-selector {
    flex-wrap: wrap;
  }
}

.dataTables_wrapper .bs-select .form-select {
  margin-left: 0 !important;
}

.ris-options {
  z-index: 9;
}

.position-relative .toolbar.button-items {
  position: absolute;
  top: -40px;
  right: 0;
}

div.react-datepicker-popper {
  z-index: 2 !important;
}

.disabled-link {
  pointer-events: none;
}

.vertical-menu.filters {
  padding-top: 140px;
}

.filter-period-col {
  width: 20.66667%;
}

